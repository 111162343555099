function makeTask(
  userPrompt,
  modelName,
  temperature,
  maxTokens,
  isUseMaxTokensEnabled,
  isUseJSONFormatEnabled,
  systemPrompt,
  index
) {
  let messages = [];

  if (systemPrompt.length > 0) {
    messages.push({ role: "system", content: systemPrompt });
  }

  messages.push({ role: "user", content: userPrompt });

  return {
    custom_id: `task-${index}`,
    method: "POST",
    url: "/v1/chat/completions",
    body: {
      model: modelName,
      ...(isUseJSONFormatEnabled
        ? { response_format: { type: "json_object" } }
        : {}),
      ...(isUseMaxTokensEnabled && maxTokens !== null
        ? { max_tokens: maxTokens }
        : {}),
      temperature: temperature,
      messages: messages,
    },
  };
}

const generateOpenAITemplates = (
  prompts,
  modelName,
  temperature,
  maxTokens,
  isUseMaxTokensEnabled,
  isUseJSONFormatEnabled,
  systemPrompt
) => {
  return prompts.map((prompt, index) =>
    makeTask(
      prompt,
      modelName,
      temperature,
      maxTokens,
      isUseMaxTokensEnabled,
      isUseJSONFormatEnabled,
      systemPrompt,
      index
    )
  );
};

export default generateOpenAITemplates;
