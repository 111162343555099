import { getEncoding, encodingForModel } from "js-tiktoken";

const modelToEncoding = {
  "gpt-4o": "o200k_base",
  "gpt-4o-2024-05-13": "o200k_base",
  "gpt-4": "cl100k_base",
  "gpt-3.5-turbo": "cl100k_base",
  "gpt-3.5-turbo-0125": "cl100k_base",
  "gpt-3.5": "cl100k_base",
  "gpt-35-turbo": "cl100k_base",
  "davinci-002": "cl100k_base",
  "babbage-002": "cl100k_base",
  "text-embedding-ada-002": "cl100k_base",
  "text-embedding-3-small": "cl100k_base",
  "text-embedding-3-large": "cl100k_base",
  "text-davinci-003": "p50k_base",
  "text-davinci-002": "p50k_base",
  "text-davinci-001": "r50k_base",
  "text-curie-001": "r50k_base",
  "text-babbage-001": "r50k_base",
  "text-ada-001": "r50k_base",
  davinci: "r50k_base",
  curie: "r50k_base",
  babbage: "r50k_base",
  ada: "r50k_base",
  "code-davinci-002": "p50k_base",
  "code-davinci-001": "p50k_base",
  "code-cushman-002": "p50k_base",
  "code-cushman-001": "p50k_base",
  "davinci-codex": "p50k_base",
  "cushman-codex": "p50k_base",
  "text-davinci-edit-001": "p50k_edit",
  "code-davinci-edit-001": "p50k_edit",
  "text-similarity-davinci-001": "r50k_base",
  "text-similarity-curie-001": "r50k_base",
  "text-similarity-babbage-001": "r50k_base",
  "text-similarity-ada-001": "r50k_base",
  "text-search-davinci-doc-001": "r50k_base",
  "text-search-curie-doc-001": "r50k_base",
  "text-search-babbage-doc-001": "r50k_base",
  "text-search-ada-doc-001": "r50k_base",
  "code-search-babbage-code-001": "r50k_base",
  "code-search-ada-code-001": "r50k_base",
  gpt2: "gpt2",
  "gpt-2": "gpt2",
};

const encoders = {
  cl100k_base: getEncoding("cl100k_base"),
  o200k_base: getEncoding("o200k_base"),
};

function encode(modelName, text) {
  return encoders[modelToEncoding[modelName]].encode(text);
}

export { encode };
