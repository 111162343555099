import React from "react";

const MaxTokensInput = ({
  isUseMaxTokensEnabled,
  setUseMaxTokensEnabled,
  maxTokens,
  setMaxTokens,
}) => {
  const handleCheckboxChange = (e) => {
    setUseMaxTokensEnabled(e.target.checked);
    if (!e.target.checked) {
      setMaxTokens(null);
    } else if (maxTokens === null) {
      setMaxTokens(1024);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMaxTokens(value === "" ? null : parseInt(value, 10));
  };

  return (
    <div className="grid">
      <label>
        <input
          type="checkbox"
          checked={isUseMaxTokensEnabled}
          onChange={handleCheckboxChange}
          className="mr-2"
        />
        Set max output tokens
        <span
          className="i-button"
          data-placement="right"
          data-tooltip="This will control, for each input prompt, the maximum amount of tokens that will be present in each output prompt."
        >
          &#9432;
        </span>
      </label>
      {isUseMaxTokensEnabled && (
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={isUseMaxTokensEnabled ? maxTokens ?? "" : ""}
          onChange={handleInputChange}
          disabled={!isUseMaxTokensEnabled}
          placeholder="Set number of max tokens to generate for each output response."
          className={isUseMaxTokensEnabled ? "" : "opacity-50"}
        />
      )}
    </div>
  );
};

export default MaxTokensInput;
