import * as React from "react";

function Nav({ currentPage }) {
  const isActive = (pageName) => {
    return currentPage === pageName;
  };

  return (
    <nav className="flex justify-between items-center p-4 bg-gray-800 text-white">
      <ul>
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
            width="26"
            height="26"
            className="mr-2"
          >
            <rect width="100%" height="100%" fill="rgba(0,0,0,0)" />
            <circle cx="100" cy="60" r="20" fill="#ffffff" opacity="0.7" />
            <circle cx="140" cy="100" r="20" fill="#ffffff" opacity="0.8" />
            <circle cx="100" cy="140" r="20" fill="#ffffff" opacity="0.9" />
            <circle cx="60" cy="100" r="20" fill="#ffffff" />
          </svg>
          <strong>Batchmon</strong>
        </li>
      </ul>
      <ul>
        <li>
          <a href="/" aria-current={isActive("home") ? "page" : undefined}>
            Prompt Generator
          </a>
        </li>
        <li>
          <a href="/blog" aria-current={isActive("blog") ? "page" : undefined}>
            Blog
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
