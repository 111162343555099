import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import sampleData from "./sampleData";
import TableComponent from "./TableComponent";
import generateTemplates from "./generateTemplates";
import CSVFilePicker from "./CSVFilePicker";
import TextArea from "./TextArea";
import FooterCopyright from "./FooterCopyright";
import downloadZipArchive from "./downloadZipArchive";
import OpenAICard from "./OpenAICard";
import Nav from "./Nav";

function generateFiles(template, columnData) {
  const allPrompts: string[] = generateTemplates(template, columnData);
  const allFiles = Object.fromEntries(
    allPrompts.map((prompt, i) => [`prompt${i}.txt`, prompt])
  );
  return allFiles;
}

// Used for compressing the big data into a single object for examples.
const mapFirstItems = (obj) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, [v[0]]]));

function App() {
  const [template, setTemplate] = useState("");

  const emptyData = {
    row_oriented: {
      headers: [],
      data: [[]],
    },
    column_oriented: {},
  };

  const [matrixData, setMatrixData] = useState(null);
  const [singleExample, setSingleExample] = useState("");
  const dataToUse = matrixData || emptyData;

  useEffect(() => {
    if (dataToUse.row_oriented.data.length == 0) return;
    const dataSample = mapFirstItems(dataToUse.column_oriented);
    setSingleExample(generateTemplates(template, dataSample)[0]);
  }, [dataToUse, template]);

  const zipDownload = () => {
    const allFiles = generateFiles(template, dataToUse.column_oriented);
    downloadZipArchive(allFiles, "prompts.zip");
  };

  return (
    <div>
      <main className="container">
        <Nav currentPage={"home"} />
        <div className="App">
          <div>
            <article>
              <h3 style={{ textAlign: "center" }}>Batch Prompt Generator</h3>
              <p>
                Batchmon is a powerful, free tool designed to help you create
                customized batch prompts effortlessly. Simply upload a CSV file,
                use our template language to generate prompts with variables,
                and export your prompts as JSONL or zip files. Perfect for
                feeding data to OpenAI's Batch API, Batchmon streamlines your
                batch processing needs.
              </p>
              <p>
                Not familiar with OpenAI's Batch API? Check out our{" "}
                <a href="/blog/openai-api-discount/">blog here.</a>
              </p>
            </article>

            <article>
              <h4>⬆️ Upload your data</h4>
              <p>Upload your data as a CSV file to get started.</p>
              <p>
                Please ensure your file is no larger than 10 megabytes and
                contains a maximum of 10,000 rows. We are actively working on
                optimizing the tool to support larger files in the future.
              </p>
              <CSVFilePicker setMatrixData={setMatrixData} />
              <TableComponent data={dataToUse.row_oriented} />
              {matrixData == null && (
                <p>
                  No CSV file?&nbsp;
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setMatrixData(sampleData);
                    }}
                  >
                    Click here to use sample data
                  </a>
                  &nbsp;to try out Batchmon.
                </p>
              )}
            </article>
            <article>
              <h3>📝 Write your prompt template</h3>
              <p>
                Now, write a prompt to integrate your data. You can use
                variables like <code>{"{var}"}</code> to customize each prompt.
              </p>
              <p>
                For example, if your CSV has a column named "name," you can use{" "}
                <code>{"{name}"}</code> in your template to insert the
                corresponding value.
              </p>
              <TextArea
                template={template}
                setTemplate={setTemplate}
                sampleData={dataToUse.column_oriented}
              />
            </article>
            <article>
              <h3>🚀 Generate your batch of prompts</h3>
              <article>
                <h4>Download prompts as .txt files</h4>
                <button onClick={zipDownload}>💾 Download zip</button>
              </article>
            </article>
            <OpenAICard
              template={template}
              dataToUse={dataToUse}
              singleExample={singleExample}
            />
          </div>
        </div>
      </main>
      <footer>
        <div className="footerContainer">
          {/* <h3>Subscribe to our mailing list.</h3>
          <br />
          <form>
            <fieldset role="group">
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                autoComplete="email"
              />
              <input type="submit" value="Subscribe" />
            </fieldset>
          </form>
          <br /> */}
          <FooterCopyright />
          <br />
        </div>
      </footer>
    </div>
  );
}

export default App;
