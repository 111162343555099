import React, { useEffect, useState } from "react";
import { getEncoding, encodingForModel } from "js-tiktoken";
import { saveAs } from "file-saver";

import generateTemplates from "./generateTemplates";
import generateOpenAITemplates from "./generateOpenAITemplates";
import { supportedModels, costForTokens } from "./openAIModels";
import MaxTokensInput from "./MaxTokensInput";
import { encode } from "./openAIEncodings";
import TextArea from "./TextArea";

const PrettyPrintJson = ({ data }) => {
  return (
    <div className="previewPane">
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

function LocalizedNumber(number) {
  const locale = navigator.language;
  const formattedNumber = new Intl.NumberFormat(locale).format(number);
  return <span>{formattedNumber}</span>;
}

function formatUSDCurrency(amount) {
  const locale = navigator.language || "en-US";
  // Round to nearest 10,000th of a penny (6 decimal places)
  const roundedAmount = Math.ceil(amount * 1000000) / 1000000;

  try {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 6,
    });

    // Format the number
    let formatted = formatter.format(roundedAmount);

    // Remove trailing zeros after the second decimal place, but keep at least two decimal places
    formatted = formatted.replace(/(\.\d{2,})0+$/, "$1");

    // If we end up with just two zeros after the decimal, keep them
    if (formatted.endsWith(".00")) {
      return formatted;
    }

    // Remove trailing decimal point if all decimal places were zeros
    return formatted.replace(/\.$/, "");
  } catch (error) {
    console.error("Error formatting currency:", error);
    // Fallback formatting
    return `$${roundedAmount.toFixed(2)}`;
  }
}

const TemperatureSlider = ({ temperature, setTemperature }) => {
  const handleChange = (event) => {
    setTemperature(parseFloat(event.target.value));
  };

  return (
    <div className="grid">
      <label htmlFor="temperature">Temperature</label>
      <div style={{ display: "flex", gap: "1rem" }}>
        <span style={{ minWidth: "2.5em", textAlign: "right" }}>
          {temperature.toFixed(1)}
        </span>
        <input
          type="range"
          id="temperature"
          name="temperature"
          min="0"
          max="2"
          step="0.1"
          value={temperature}
          onChange={handleChange}
          style={{ flex: 1 }}
        />
      </div>
    </div>
  );
};

const JSONCheckbox = ({ useJSONResponse, setUseJSONResponse }) => {
  return (
    <label className="pico-switch">
      <input
        type="checkbox"
        checked={useJSONResponse}
        onChange={(e) => setUseJSONResponse(e.target.checked)}
      />
      <span>Force JSON output</span>
    </label>
  );
};

const CustomSystemPromptCheckbox = ({
  useCustomSystemPrompt,
  setUseCustomSystemPrompt,
}) => {
  return (
    <label className="pico-switch">
      <input
        type="checkbox"
        checked={useCustomSystemPrompt}
        onChange={(e) => setUseCustomSystemPrompt(e.target.checked)}
      />
      <span>Use custom system prompt</span>
    </label>
  );
};

const ModelSelector = ({ supportedModels, modelToUse, setModelToUse }) => {
  return (
    <div>
      <label>
        Select model
        <select
          id="model-select"
          value={modelToUse}
          onChange={(e) => setModelToUse(e.target.value)}
          style={{ marginLeft: "0.5rem" }}
        >
          {supportedModels.map((model) => (
            <option key={model} value={model}>
              {model}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

function OpenAICard({ template, dataToUse, singleExample }) {
  const [systemPrompt, setSystemPrompt] = useState("");

  const [openAIExample, setOpenAIExample] = useState({});
  const [tokenCount, setTokenCount] = useState(0);
  const [modelToUse, setModelToUse] = useState("gpt-4o");
  const [isUseMaxTokensEnabled, setUseMaxTokensEnabled] = useState(false);
  const [maxTokens, setMaxTokens] = useState(1024);
  const [templateCount, setTemplateCount] = useState(0);
  const [temperature, setTemperature] = useState(0);
  const [useJSONResponse, setUseJSONResponse] = useState(false);
  const [useCustomSystemPrompt, setUseCustomSystemPrompt] = useState(false);

  // Token counter.
  useEffect(() => {
    const allTemplates = generateTemplates(template, dataToUse.column_oriented);
    setTemplateCount(allTemplates.length);

    const tokens = allTemplates.map((x) =>
      encode(modelToUse, systemPrompt + " " + x)
    );

    const allTokensCount = tokens.reduce((sum, arr) => sum + arr.length, 0);
    setTokenCount(allTokensCount);
  }, [template, systemPrompt, dataToUse, modelToUse]);

  useEffect(() => {
    setOpenAIExample(
      generateOpenAITemplates(
        [singleExample],
        modelToUse,
        temperature,
        maxTokens,
        isUseMaxTokensEnabled,
        useJSONResponse,
        systemPrompt
      )[0]
    );
  }, [
    modelToUse,
    singleExample,
    temperature,
    maxTokens,
    isUseMaxTokensEnabled,
    useJSONResponse,
    systemPrompt,
  ]);

  const openAIDownload = () => {
    const allPrompts = generateTemplates(template, dataToUse.column_oriented);
    const openAITemplates = generateOpenAITemplates(
      allPrompts,
      modelToUse,
      temperature,
      maxTokens,
      isUseMaxTokensEnabled,
      useJSONResponse,
      systemPrompt
    );
    const jsonStrings = openAITemplates.map((x) => JSON.stringify(x));
    const joinedJson = jsonStrings.join("\n");

    let blob = new Blob([joinedJson], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, "batch.jsonl");
  };

  const inputTokenCost = costForTokens(modelToUse, tokenCount, "input");
  const outputTokenCost = costForTokens(modelToUse, tokenCount, "output");
  const totalCost = inputTokenCost + outputTokenCost;

  return (
    <article>
      <h4>Download prompts as OpenAI Batch .jsonl format</h4>
      <br />
      <ModelSelector
        modelToUse={modelToUse}
        setModelToUse={setModelToUse}
        supportedModels={supportedModels}
      />
      <br />
      <MaxTokensInput
        isUseMaxTokensEnabled={isUseMaxTokensEnabled}
        setUseMaxTokensEnabled={setUseMaxTokensEnabled}
        maxTokens={maxTokens}
        setMaxTokens={setMaxTokens}
      />
      <JSONCheckbox
        setUseJSONResponse={setUseJSONResponse}
        useJSONResponse={useJSONResponse}
      />
      <CustomSystemPromptCheckbox
        useCustomSystemPrompt={useCustomSystemPrompt}
        setUseCustomSystemPrompt={setUseCustomSystemPrompt}
      />
      {useCustomSystemPrompt && (
        <div>
          <br />
          <TextArea
            template={systemPrompt}
            setTemplate={setSystemPrompt}
            sampleData={dataToUse.column_oriented}
            textOverPrompt="System prompt"
          />
        </div>
      )}
      <br />
      <TemperatureSlider
        temperature={temperature}
        setTemperature={setTemperature}
      />
      <br />

      <div className="grid">
        <div className="grid">
          <div>
            <h4>Input Tokens</h4>
            <div className="data-row">
              <span className="data-label">Token count</span>
              <span className="data-value">{LocalizedNumber(tokenCount)}</span>
            </div>
            <div className="data-row">
              <span className="data-label">Estimated input cost</span>
              <span className="data-value">
                {formatUSDCurrency(inputTokenCost)}
              </span>
            </div>
          </div>
        </div>
        {isUseMaxTokensEnabled && (
          <div className="grid">
            <div>
              <h4>Output Tokens</h4>
              <div className="data-row">
                <span className="data-label">Token count</span>
                <span className="data-value">
                  {LocalizedNumber(maxTokens * templateCount)}
                </span>
              </div>
              <div className="data-row">
                <span className="data-label">Estimated output cost</span>
                <span className="data-value">
                  {formatUSDCurrency(outputTokenCost)}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <br />

      <div className="data-row">
        <span className="data-label">Total prompts</span>
        <span className="data-value">{templateCount}</span>
      </div>
      {isUseMaxTokensEnabled && (
        <div className="data-row">
          <span className="data-label">Total estimated cost</span>
          <span className="data-value">{formatUSDCurrency(totalCost)}</span>
        </div>
      )}

      <PrettyPrintJson data={openAIExample} />

      <button onClick={() => openAIDownload()}>💾 Download jsonl</button>
    </article>
  );
}

export default OpenAICard;
