// Import the necessary libraries
import JSZip from "jszip";
import { saveAs } from "file-saver";

/**
 * Creates a zip archive from a map of fileName => fileContents.
 * @param filesMap - A map where the key is the file name and the value is the file contents.
 * @returns - A Promise that resolves to the generated zip file as a Blob.
 */
async function createZipArchive(filesMap: {
  [fileName: string]: string;
}): Promise<Blob> {
  const zip = new JSZip();

  // Iterate through the map and add each file to the zip
  for (const [fileName, fileContents] of Object.entries(filesMap)) {
    zip.file(fileName, fileContents);
  }

  // Generate the zip file as a Blob
  const zipBlob = await zip.generateAsync({ type: "blob" });

  return zipBlob;
}

/**
 * Downloads the zip archive created from a map of fileName => fileContents.
 * @param filesMap - A map where the key is the file name and the value is the file contents.
 * @param zipFileName - The name of the zip file to be downloaded.
 */
async function downloadZipArchive(
  filesMap: { [fileName: string]: string },
  zipFileName: string
): Promise<void> {
  // Create the zip archive
  const zipBlob = await createZipArchive(filesMap);

  // Trigger the download
  saveAs(zipBlob, zipFileName);
}

export default downloadZipArchive;

// // Example usage
// const filesMap = {
//   "file1.txt": "This is the content of file1.",
//   "file2.txt": "This is the content of file2.",
//   "file3.txt": "This is the content of file3.",
// };

// downloadZipArchive(filesMap, "archive.zip");
