// Taken from https://platform.openai.com/docs/guides/batch/model-availability.
const models = [
  "gpt-4o",
  "gpt-4-turbo",
  "gpt-4",
  "gpt-4-32k",
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-16k",
  "gpt-4-turbo-preview",
  "gpt-4-vision-preview",
  "gpt-4-turbo-2024-04-09",
  "gpt-4-0314",
  "gpt-4-32k-0314",
  "gpt-4-32k-0613",
  "gpt-3.5-turbo-0301",
  "gpt-3.5-turbo-16k-0613",
  "gpt-3.5-turbo-1106",
  "gpt-3.5-turbo-0613",
  "text-embedding-3-large",
  "text-embedding-3-small",
  "text-embedding-ada-002",
];

// Taken from https://github.com/openai/tiktoken/blob/9d01e5670ff50eb74cdb96406c7f3d9add0ae2f8/tiktoken/model.py#L20
const modelToEncoding = {
  "gpt-4o": "o200k_base",
  "gpt-4": "cl100k_base",
  "gpt-3.5-turbo": "cl100k_base",
  "gpt-3.5": "cl100k_base",
  "gpt-35-turbo": "cl100k_base",
  "davinci-002": "cl100k_base",
  "babbage-002": "cl100k_base",
  "text-embedding-ada-002": "cl100k_base",
  "text-embedding-3-small": "cl100k_base",
  "text-embedding-3-large": "cl100k_base",
  "text-davinci-003": "p50k_base",
  "text-davinci-002": "p50k_base",
  "text-davinci-001": "r50k_base",
  "text-curie-001": "r50k_base",
  "text-babbage-001": "r50k_base",
  "text-ada-001": "r50k_base",
  davinci: "r50k_base",
  curie: "r50k_base",
  babbage: "r50k_base",
  ada: "r50k_base",
  "code-davinci-002": "p50k_base",
  "code-davinci-001": "p50k_base",
  "code-cushman-002": "p50k_base",
  "code-cushman-001": "p50k_base",
  "davinci-codex": "p50k_base",
  "cushman-codex": "p50k_base",
  "text-davinci-edit-001": "p50k_edit",
  "code-davinci-edit-001": "p50k_edit",
  "text-similarity-davinci-001": "r50k_base",
  "text-similarity-curie-001": "r50k_base",
  "text-similarity-babbage-001": "r50k_base",
  "text-similarity-ada-001": "r50k_base",
  "text-search-davinci-doc-001": "r50k_base",
  "text-search-curie-doc-001": "r50k_base",
  "text-search-babbage-doc-001": "r50k_base",
  "text-search-ada-doc-001": "r50k_base",
  "code-search-babbage-code-001": "r50k_base",
  "code-search-ada-code-001": "r50k_base",
  gpt2: "gpt2",
  "gpt-2": "gpt2",
};

const costPerToken = {
  "gpt-4o": {
    input: 0.005 / 1000,
    output: 0.015 / 1000,
  },
  "gpt-4o-2024-05-13": {
    input: 0.005 / 1000,
    output: 0.015 / 1000,
  },
  "gpt-3.5-turbo-0125": {
    input: 0.0005 / 1000,
    output: 0.0015 / 1000,
  },
  "gpt-3.5-turbo-instruct": {
    input: 0.0015 / 1000,
    output: 0.002 / 1000,
  },
};

// TODO(): Make this more robust for errors.
// model: (string) Model name.
// tokenCount: (int) The actual count of tokens.
// type: (string) "input" or "output".
function costForTokens(model, tokenCount, type) {
  console.log(model, costPerToken[model]);
  const costPerTokenForModel = costPerToken[model][type];
  return costPerTokenForModel * tokenCount;
}

const supportedModels = [
  "gpt-4o",
  "gpt-4o-2024-05-13",
  "gpt-3.5-turbo-0125",
  // "gpt-3.5-turbo-instruct",
];

export {
  models,
  modelToEncoding,
  costPerToken,
  supportedModels,
  costForTokens,
};
