import React, { useState, useEffect } from "react";
import ParseCsv from "./ParseCsv"; // Assuming this import exists

const CSVFilePicker = ({ setMatrixData, maxSizeMB = 10 }) => {
  const [error, setError] = useState(null);
  const [hasHeader, setHasHeader] = useState(true);
  const [rawCSVContent, setRawCSVContent] = useState(null);
  const [fileName, setFileName] = useState(null);

  const parseCSVContent = (content, hasHeader) => {
    const parseResult = ParseCsv(content, hasHeader);
    if (
      parseResult.success &&
      parseResult.matrixData &&
      parseResult.matrixData.row_oriented &&
      parseResult.matrixData.row_oriented.data &&
      parseResult.matrixData.row_oriented.data.length > 0
    ) {
      if (parseResult.matrixData.row_oriented.data.length < 10_000) {
        if (!hasHeader) {
          const columnCount =
            parseResult.matrixData.row_oriented.data[0].length;
          const defaultHeaders = Array.from(
            { length: columnCount },
            (_, i) => `col${i + 1}`
          );
          parseResult.matrixData.row_oriented.headers = defaultHeaders;
          parseResult.matrixData.column_oriented = Object.fromEntries(
            defaultHeaders.map((header, index) => [
              header,
              parseResult.matrixData.row_oriented.data.map((row) => row[index]),
            ])
          );
        }
        return { success: true, data: parseResult.matrixData };
      } else {
        return {
          success: false,
          error:
            "This tool is optimized for up to 10,000 rows. Please try a smaller dataset for now. We're working on supporting larger files in the future.",
        };
      }
    } else {
      return {
        success: false,
        error: parseResult.errorMsg || "Failed to parse CSV.",
      };
    }
  };

  useEffect(() => {
    if (rawCSVContent) {
      const result = parseCSVContent(rawCSVContent, hasHeader);
      if (result.success) {
        setMatrixData(result.data);
        setError(null);
      } else {
        setError(result.error);
        setMatrixData(null);
      }
    }
  }, [rawCSVContent, hasHeader, setMatrixData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setError(null);
    setFileName(null);
    setRawCSVContent(null);

    if (!file) {
      setError("No file selected.");
      return;
    }

    const fileSizeMB = file.size / (1024 * 1024);
    if (fileSizeMB > maxSizeMB) {
      setError(`File size exceeds the limit of ${maxSizeMB} megabytes.`);
      return;
    }

    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      setError("Please select a CSV file.");
      return;
    }

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      if (typeof content === "string") {
        setRawCSVContent(content);
      } else {
        setError("Failed to read file as text.");
      }
    };
    reader.onerror = () => {
      setError("Error reading file.");
    };
    reader.readAsText(file);
  };

  const handleHasHeaderChange = (e) => {
    setHasHeader(e.target.checked);
  };

  return (
    <div className="csv-file-picker">
      <label htmlFor="csv-file-input" className="file-input-label">
        Select CSV file
        <input
          id="csv-file-input"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
        />
      </label>
      {fileName && (
        <div className="grid">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={hasHeader}
              onChange={handleHasHeaderChange}
            />
            CSV has header.
          </label>
        </div>
      )}
      {error && <article className="parse-error">{error}</article>}
    </div>
  );
};

export default CSVFilePicker;
