import React from "react";

const PREVIEW_LIMIT = 50;

interface MatrixDataRowOriented {
  headers: string[];
  data: string[][];
}

interface TableProps {
  data: MatrixDataRowOriented;
}

const Table: React.FC<TableProps> = ({ data }) => {
  if (!data || !data.headers || !data.data || data.data.length === 0) {
    return;
  }

  return (
    <div className="table-container">
      <table className="styled-table">
        <thead>
          <tr>
            {data.headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface TableComponentProps {
  data: MatrixDataRowOriented;
}

const TableComponent: React.FC<TableComponentProps> = ({ data }) => {
  const previewData = {
    headers: data.headers,
    data: data.data.slice(0, PREVIEW_LIMIT),
  };

  const wasLargerThanLimit = data.data.length > PREVIEW_LIMIT;

  return (
    <div>
      <Table data={previewData} />
      {wasLargerThanLimit && (
        <div style={{ textAlign: "center" }}>
          (Preview of first {PREVIEW_LIMIT} items)
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default TableComponent;
