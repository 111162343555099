const sampleData = {
  row_oriented: {
    headers: ["name", "age", "occupation", "salary"],
    data: [
      ["John Doe", "35", "Software Engineer", "85000"],
      ["Jane Smith", "28", "Data Analyst", "65000"],
      ["Mike Johnson", "42", "Project Manager", "95000"],
      ["Emily Brown", "31", "Marketing Specialist", "70000"],
      ["David Lee", "39", "Product Designer", "80000"],
    ],
  },
  column_oriented: {
    name: [
      "John Doe",
      "Jane Smith",
      "Mike Johnson",
      "Emily Brown",
      "David Lee",
    ],
    age: ["35", "28", "42", "31", "39"],
    occupation: [
      "Software Engineer",
      "Data Analyst",
      "Project Manager",
      "Marketing Specialist",
      "Product Designer",
    ],
    salary: ["85000", "65000", "95000", "70000", "80000"],
  },
};

export default sampleData;
