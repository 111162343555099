interface MatrixData {
  row_oriented: {
    headers: string[];
    data: string[][];
  };
  column_oriented: {
    [key: string]: string[];
  };
}

interface ParseResult {
  success: boolean;
  errorMsg: string;
  matrixData: MatrixData | null;
}

function ParseCsv(csvString: string, has_header: boolean = true): ParseResult {
  const result: ParseResult = {
    success: true,
    errorMsg: "",
    matrixData: null,
  };

  try {
    // Split the CSV string into rows
    const rows = csvString
      .split("\n")
      .map((row) => row.trim())
      .filter((row) => row.length > 0);

    if (rows.length === 0) {
      throw new Error("CSV is empty");
    }

    // Parse each row, handling escaped commas
    const parsedRows = rows.map((row) => {
      const cells: string[] = [];
      let currentCell: string[] = [];
      let inQuotes = false;

      for (let i = 0; i < row.length; i++) {
        const char = row[i];
        if (char === '"') {
          inQuotes = !inQuotes;
        } else if (char === "," && !inQuotes) {
          cells.push(currentCell.join("").trim());
          currentCell = [];
        } else {
          currentCell.push(char);
        }
      }
      cells.push(currentCell.join("").trim());
      return cells;
    });

    // Determine headers
    let headers: string[];
    let dataRows: string[][];

    if (has_header) {
      headers = parsedRows[0];
      dataRows = parsedRows.slice(1);
    } else {
      headers = Array.from(
        { length: parsedRows[0].length },
        (_, i) => `col${i + 1}`
      );
      dataRows = parsedRows;
    }

    // Create row-oriented data
    const rowOriented: MatrixData["row_oriented"] = {
      headers,
      data: dataRows,
    };

    // Create column-oriented data
    const columnOriented: MatrixData["column_oriented"] = {};
    headers.forEach((header, index) => {
      columnOriented[header] = dataRows.map((row) => row[index]);
    });

    result.matrixData = {
      row_oriented: rowOriented,
      column_oriented: columnOriented,
    };
  } catch (error) {
    result.success = false;
    result.errorMsg =
      error instanceof Error ? error.message : "An unknown error occurred";
  }

  return result;
}

export default ParseCsv;
