import React, { useState, useCallback } from "react";

// Import the generateTemplates function
import generateTemplates from "./generateTemplates";

// Define the data type
type Data = { [key: string]: string[] };

// TextInput component
const TextInput = ({ value, onChange, textOverPrompt }) => {
  return (
    <div>
      <h5>{textOverPrompt}</h5>
      <textarea
        className="tall"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter your prompt template here."
      />
    </div>
  );
};

// Preview component
const Preview = ({ template, data }) => {
  const previewText = generateTemplates(template, data)[0] || "";

  return (
    <div>
      <h5>Live preview</h5>
      <pre className="tall previewPane">{previewText}</pre>
    </div>
  );
};

// Main TextArea component
const TextArea = ({
  sampleData,
  template,
  setTemplate,
  textOverPrompt = "User prompt",
}) => {
  // const [template, setTemplate] = useState("");

  const handleTemplateChange = useCallback((newTemplate) => {
    setTemplate(newTemplate);
  }, []);

  return (
    <div>
      <div className="grid">
        <TextInput
          value={template}
          onChange={handleTemplateChange}
          textOverPrompt={textOverPrompt}
        />
        <Preview template={template} data={sampleData} />
      </div>
    </div>
  );
};

export default TextArea;
