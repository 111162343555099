type Data = { [key: string]: string[] };

// Primitive function.
function _generateTemplates(
  template: string,
  leftEscape: string,
  rightEscape: string,
  data: Data
): string[] {
  const keys = Object.keys(data);
  if (keys.length == 0) return [template];
  const numItems = data[keys[0]].length;

  // Check if all data arrays have the same length
  for (let key of keys) {
    if (data[key].length !== numItems) {
      throw new Error("All data values must have the same number of items");
    }
  }

  const result: string[] = [];

  for (let i = 0; i < numItems; i++) {
    let tempStr = template;
    for (let key of keys) {
      const regex = new RegExp(leftEscape + key + rightEscape, "g");
      tempStr = tempStr.replace(regex, data[key][i]);
    }
    result.push(tempStr);
  }

  return result;
}

function generateTemplates(template: string, data: Data) {
  return _generateTemplates(template, "{", "}", data);
}

export default generateTemplates;
